import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import cn from 'classnames';
import { getLocale, setLocale } from '../../locales';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

const languageOptions = ['en', 'es'];
const languageLabels = {
  en: 'English',
  es: 'Español'
};

const SelectLanguage = ({ className = '', ...props }) => {
  const [ locale, setLocaleState ] = useState(getLocale());

  useEffect(() => {
    setLocale(locale);
  }, [ locale ]);

  const {
    buttonProps,
    itemProps: [itemProps],
    isOpen,
    setIsOpen
  } = useDropdownMenu(languageOptions?.length);

  const selectOption = locale => {
    setIsOpen(false);
    setLocaleState(locale);
  };

  return (
    <div className={cn(className, 'select-language')}>
      <button
        className="select-language-container"
        type="button"
        onClick={() => setIsOpen(true)}
        {...buttonProps}
        {...props}
      >
        <div className="select-language-container-name">{languageLabels[locale]}</div>
        <svg className={cn('select-language-arrow', isOpen && 'select-language-arrow-open')} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.44838e-06 9.99999L0.823987 10.8478L9.73696 20L12 18.3044L3.91103 9.99999L12 1.69561L9.73696 -9.421e-08L0.823988 9.1522L1.44838e-06 9.99999Z"
            fill="#000"
          />
        </svg>
        <div style={{ display: isOpen ? 'inherit' : 'none' }} className="select-language-options" role="menu">
          {languageOptions.map(lang => {
            const onSelected = () => selectOption(lang);

            return (
              <span
                key={`lang-option-${lang}`}
                {...itemProps}
                role="menuitem"
                onClick={onSelected}
                onKeyDown={onSelected}
                tabIndex={0}
                className="select-language-options-item"
              >
                {languageLabels[lang]}
              </span>
            );
          })}
        </div>
      </button>
    </div>
  );
};

SelectLanguage.propTypes = {
  className: string
};

export default SelectLanguage;
