import React from "react";

export const renderTable = table => {
  const [ header, ...rows ] = table;

  return <table>
    <thead>
      <tr>{ header.map(th => <th><b>{th}</b></th>) }</tr>
    </thead>
    <tbody>
      { rows.map(tds => <tr>{ tds.map(td => <td>{td}</td>) }</tr>) }
    </tbody>
  </table>;
};

const portalUrls = {
  prod: 'https://portal.springoakscapital.com/',
  dev: 'https://payment-portal-dev.ai.springoakscapital.com/'
};

export const getPortalUrl = () => {
  const host = location?.host;

  return !host || host.includes('dev') === false
    ? portalUrls.prod
    : portalUrls.dev;
};
