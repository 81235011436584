import { useEffect, useCallback } from 'react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

export default (event, options = {}) => {
  const mixpanel = useMixpanel();
  mixpanel.init(process.env.MIXPANEL_API_KEY, { persistence: 'localStorage' });

  const trackEvent = useCallback((event, options) => mixpanel.track(event, options), [mixpanel]);

  useEffect(() => {
    event && trackEvent(event, options);
  }, [event, options, trackEvent]);

  return {
    trackEvent,
  };
};
