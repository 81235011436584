import en from './en.js';
import es from './es.js';

export const messages = {
  en,
  es
};

const inBuildServer = typeof window === 'undefined';

export const storageKey = 'soc_lo';
export const setLocale = inBuildServer
  ? () => {}
  : locale => {
      const oldLocale = getLocale();

      localStorage.setItem(storageKey, locale);
      
      if (locale !== oldLocale && typeof location !== 'undefined') {
        location.href = location.href.replace(/\?[^#].*/, '');
      }
    };

export const getLocale = inBuildServer
  ? () => 'en'
  : () => {
      const queryLocale = location?.search && location.search.match(/[?]?lang=([^&]*)/)?.[1];
      const locale = queryLocale || localStorage.getItem(storageKey) || navigator.language.replace(/-.*$/g, '');

      return locale && locale in messages
        ? locale
        : 'en';
    };

export const getMessages = () => {
  return messages[getLocale()];
};
