import React from 'react';
import { number, string } from 'prop-types';

const HamburgerIcon = ({ width = 60, height = 60, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    aria-label="Menu Icon"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={className}
  >
    <circle cx="30" cy="30" r="30" fill="#F0F7FF" />
    <line
      x1="19.582"
      y1="24.2891"
      x2="40.4176"
      y2="24.2891"
      stroke="#25466D"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="19.582"
      y1="34.1758"
      x2="40.4176"
      y2="34.1758"
      stroke="#25466D"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

HamburgerIcon.propTypes = {
  width: number,
  height: number,
  className: string,
};

export default HamburgerIcon;
