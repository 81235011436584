import React from 'react';
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import { bool } from 'prop-types';

import Button from '../common/Button/Button';
import useAnalytics from '../../hooks/useAnalytics';

import { getMessages } from '../../locales';

const Footer = ({
  hideNVResidentsDisclosure = false,
  hideNYResidentsDisclosure = false,
  hideNMLS = false,
  hideSCDisclosure = true,
}) => {
  const { trackEvent } = useAnalytics();

  const trackEvents = () => {
    trackEvent('Click Get In Touch', { Location: 'footer' });
  };

  const messages = getMessages();

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm col-md-8 title-section">
            <h3>{ReactHtmlParser(messages['footer.title'])}</h3>
            <Button
              linkTo="/contact-us"
              className="footer-button"
              buttonStyle="white"
              size="medium"
              title={messages['footer.button']}
              onClick={trackEvents}
            />
          </div>
          <div className="col-sm col-lg-3 offset-lg-1 footer-menu">
            <Link to="/">{messages['menu.home']}</Link>
            <Link to="/about">{messages['menu.whoWeAre']}</Link>
            <Link to="/privacy-policy">{messages['menu.privacy']}</Link>
          </div>
          <div className="col-sm-12 notice-section">
            <p>{ReactHtmlParser(messages['home.disclaimer'])}</p>
            {!hideNVResidentsDisclosure && (
              <>
                <h4>{messages['footer.NVDisclaimerTitle']}</h4>
                <p>{ReactHtmlParser(messages['footer.NVDisclaimer'])}</p>
              </>
            )}
            {!hideNYResidentsDisclosure && (
              <>
                <h4>{messages['footer.notice.title']}</h4>
                <p>{ReactHtmlParser(messages['footer.notice.desc'])}</p>
              </>
            )}
            {!hideSCDisclosure && (
              <>
                <h4>{ReactHtmlParser(messages['home.SCDisclaimerTitle'])}</h4>
                <p className="footer-external-link">
                  {ReactHtmlParser(messages['home.SCDisclaimer'])}
                </p>
              </>
            )}
            {!hideNMLS && <p>{ReactHtmlParser(messages['footer.notice.nmls'])}</p>}
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  hideNYResidentsDisclosure: bool,
  hideNMLS: bool,
  hideSCDisclosure: bool,
};

export default Footer;
