const config = {
  siteTitle: 'Spring Oaks Capital LLC', // Site title.
  siteTitleShort: 'Spring Oaks', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Spring Oaks Capital', // Alternative site title for SEO.
  siteLogo: '/logos/logo-1024.png', // Logo used for SEO and manifest.
  siteUrl: 'https://springoakscapital.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Spring Oaks Capital, LLC is an innovative and technology-focused consumer debt purchasing and collections platform', // Website description used for RSS feeds/meta description tag.
  siteFBAppID: '', // FB Application ID for using app insights
  googleAnalyticsID: '', // GA tracking ID.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  copyright: 'Copyright © 2020. Advanced User', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  defaultLanguage: 'en',
  supportedLanguages: ['en'],
  portalURL: process.env.PORTAL_URL,
  privacyTitle: 'Privacy Policy | Spring Oaks Capital',
  contactUsTitle: 'Contact Us | Spring Oaks Capital',
  customerSupportEmail: 'customerservice@springoakscapital.com',
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1);

module.exports = config;
