import React from 'react';
import { number, string } from 'prop-types';

const CloseIcon = ({ width = 60, height = 60, color = '#25466D', bg = '#EAEAEA', className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    aria-label="Close Icon"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={className}
  >
    <circle cx="30" cy="30" r="30" fill={bg} />
    <path
      d="M21.0629 22.5735L28.4894 30L21.0629 37.4265C20.6457 37.8437 20.6457 38.52 21.0629 38.9371C21.48 39.3543 22.1564 39.3543 22.5735 38.9371L30 31.5106L37.4265 38.9371C37.8437 39.3543 38.52 39.3543 38.9371 38.9371C39.3543 38.52 39.3543 37.8437 38.9371 37.4265L31.5106 30L38.9371 22.5735C39.3543 22.1564 39.3543 21.48 38.9371 21.0629C38.52 20.6457 37.8437 20.6457 37.4265 21.0629L30 28.4894L22.5735 21.0629C22.1564 20.6457 21.48 20.6457 21.0629 21.0629C20.6457 21.48 20.6457 22.1564 21.0629 22.5735Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
);

CloseIcon.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string,
  bg: string,
};

export default CloseIcon;
