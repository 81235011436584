import React from 'react';

export default {
  'header.viewAccount': 'View my account',
  'header.description':
    'We understand life happens and will work with you to help resolve your debt.',
  'header.title': 'The journey to being debt-free starts with a single step',
  'header.altImage': 'Woman surrounded by charts',
  'splitSection.flexiblePlanTitle': 'Flexible plans for real situations',
  'splitSection.flexiblePlanIllus': 'Woman sitting on a bench and wind blowing in the background',
  'splitSection.flexiblePlanDesc':
    'Whether you need <b>short-term flexibility</b> or a longer term payment plan, we can help find a solution that provides you with <b>peace of mind.</b>',
  'splitSection.honestyTitle': 'Honesty and Transparency',
  'splitSection.honestyIllus': 'Man and woman shaking hands',
  'splitSection.honestyDesc':
    'We will help you <b>understand your options</b> and provide you with access to your account information and documentation. No surprises.',
  'whoWeAre.title': 'Who we are',
  'whoWeAre.imageAlt': 'People chatting and looking at a computer',
  'whoWeAre.desc':
    'Simply stated, <b>we operate differently.</b> Ask our employees, clients or the thousands of consumers we have helped through unexpected situations. <b>We pride ourselves</b> on being consumer-focused problem solvers. Put your trust in our experience and expertise.',
  'whoWeAre.button': 'Learn more about us',
  'home.disclaimer':
    'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
  'home.SCDisclaimerTitle': '<b>Notice to South Carolina Residents</b></br>',
  'home.SCDisclaimer':
    '<a href="South_Carolina_Supervised_Lenders_License_2020.pdf" target="_blank">Click to view South Carolina License SLW1-1916692 (PDF)</a></br><a href="South_Carolina_Consumer_Pamphlet.pdf" target="_blank">Click to view the South Carolina Consumer Pamphlet (PDF)</a>',
  'footer.NVDisclaimerTitle': 'Notice to Nevada Residents',
  'footer.NVDisclaimer': `
    Spring Oaks Capital, LLC’s Nevada Collection Agency License number: CAD11626<br/>
    Spring Oaks Capital, LLC’s Nevada Compliance Managers are Jason Collins, number: CM12572, and Timothy Rees, number: CM12697
  `,
  'footer.title': 'Have a question about your account or Spring Oaks Capital?',
  'footer.notice.title': 'Notice to New York City Residents',
  'footer.notice.desc': `You may also reach us by calling Tim Rees at 866-539-7554.  New York City Department of Consumer Affairs License Number(s): 2117984-DCWP "To better serve you, please advise us if you have any language preference other than English.  At this time, our language access services are limited to our ability to make Spanish speaking representatives available to you upon request and providing some of our written communications to you in Spanish. In addition, a translation and description of commonly-used debt collection terms is available in multiple languages on New York City’s Department of Consumer Affairs’ website at
  <b><a href="http://www.nyc.gov/dca" target="_blank">www.nyc.gov/dca.</a></b>"`,
  'footer.notice.nmls': '<b>NMLS #1916692</b>',
  'footer.button': 'Contact us',
  'footer.knowYourRights':
    'This is a communication with a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
  'footer.copyright': '© 2024 Spring Oaks Capital, LLC, All Rights Reserved.',
  'menu.home': 'Home',
  'menu.whoWeAre': 'Who we are',
  'menu.faq': 'Faq',
  'menu.careers': 'Careers',
  'menu.privacy': 'Privacy Policy',
  'menu.getInTouch': 'Contact us',
  'WeAreHereSection.title': "We're here to help",
  'WeAreHereSection.desc':
    'We offer <b>flexible self-service</b> options to resolve your debt.<br/>If you need assistance, our knowledgeable and friendly associates are available by phone or email to help you.',
  'WeAreHereSection.Illus': 'Man surrounded by technologic devices and symbols',
  'quoteSection.title': 'What consumers are saying',
  quoteSection_quotes: [
    {
      name: 'S.C. (Connecticut)',
      quote:
        '“Ronald was wonderful! I know in collections you guys get a bad rap, but if your company is any example of how I was treated today you are doing a great job!”',
      src: 'member-quotes/avatar-ronald.jpeg',
      employee: 'Ronald',
    },
    {
      name: 'J.S. (Ohio)',
      quote:
        '“Tia is very good at her job. Nobody wants to talk to a debt collector, but Tia made me want to. She was understanding, courteous, and respectful.”',
      src: 'member-quotes/avatar-tia.jpeg',
      employee: 'Tia',
    },
    {
      name: 'M.B. (South Carolina)',
      quote:
        '“You have a wonderful employee! Charles had a wonderful attitude and made it easy to tie up loose ends. He didn’t let me shoo him off the phone, and assured me that we could come to a solution and I am glad he did. With a baby on the way, I now have one less thing to worry about.”',
      src: 'member-quotes/avatar-charles.jpeg',
      employee: 'Charles',
    },
  ],
  'privacy.title': 'General Privacy Policy',
  'privacy.ca.title': 'Privacy Policy applicable to California residents',
  'privacy.button': 'Download PDF',
  'privacy.tab-one': 'Privacy Policy 1',
  'privacy.tab-two': 'Privacy Policy 2',
  'privacy.tabs': [
    {
      tab: 'General Privacy Policy',
      link: '/privacy-policy',
    },
    {
      tab: 'Privacy Policy for California Residents',
      link: '/privacy-policy-ca',
    },
  ],
  'privacy.content': [
    {
      heading: 'Purpose',
      body: 'Spring Oaks Capital, LLC and its affiliates, including Spring Oaks Capital SPV, LLC (collectively “Spring Oaks”, “us,” or “we”) is committed to protecting the privacy and the security of the information it receives from for the security of the our assets by assets by all employees of employees of Spring Oaks, (collectively, “Users“) and to protect private information regarding its consumers as a top priority. Pursuant to the requirements of the Gramm-Leach-Bliley Act (the “GLBA“) and guidelines established by the Securities Exchange Commission regarding the Privacy of Consumer Financial Information (Regulation P), this policy (the “Policy” or the “Regulation P “Regulation P Policy”) and the related procedures contained herein are designed to comply with applicable privacy laws, including the GLBA and Regulation P, and to protect nonpublic personal information of consumers. In the event that new privacy-related laws or regulations affecting the information practices of Spring Oaks are adopted by federal or state regulators, this Policy will be revised as necessary and any changes will be changes will be disseminated and explained to all personnel.',
    },
    {
      heading: 'Scope',
      body: 'This Policy covers the practices of Spring Oaks and applies to all nonpublic personally identifiable information, including information contained in consumer reports, of our current and former customers and the consumers with whom we interact. All financial companies need to share personal information to run their everyday business. This Policy lists the reasons financial companies can share personal information, the reasons Spring Oaks chooses to share and whether you can limit this sharing.',
    },
    {
      heading: 'Policy',
      body: 'Spring Oaks and its employees recognize an ongoing commitment to the privacy of its consumers. All employees will be expected to read, understand, and abide by this policy, as well as to follow all related procedures and to uphold the standards of privacy and security set forth by Spring Oaks.',
    },
    {
      heading: 'Procedures',
      subsections: [
        {
          description:
            'In Regulation P, the SEC published guidelines, pursuant to section 501(b) of the GLBA, that address the steps a financial institution should take in order to protect consumer information. The overall security standards that must be upheld are:',
          items: [
            'Ensuring the security and confidentiality of consumer records and information;',
            'Protecting against any anticipated threats or hazards to the security or integrity of consumer records and information; and',
            'Protecting against unauthorized access to or use of consumer records or information that could result in substantial harm or inconvenience to any consumer.',
          ],
        },
        {
          heading: 'Responsibility',
          items: [
            'Each Employee has a duty to protect the nonpublic personal information of consumers collected by us.',
            'Each Employee has a duty to ensure that nonpublic personal information of consumers is shared only with employees and others in a way that is consistent with Spring Oak’s Privacy Notice and the procedures contained in this Policy.',
            'Each Employee has a duty to ensure that access to nonpublic personal information of consumers is limited as provided in the Privacy Notice and this Policy.',
            'No Employee is authorized to sell, on behalf of Spring Oaks or otherwise, nonpublic information of consumers.',
            'Employees with questions concerning the collection and sharing of, or access to, nonpublic personal information of consumers must look to Spring Oaks CCO for guidance.',
            'Violations of these policies and procedures will be addressed in a manner consistent with other disciplinary guidelines.',
          ],
        },
        {
          heading: 'Information Practices',
          description:
            'Spring Oaks limits the use, collection, and retention of consumer information to what we believe is necessary or useful to conduct our business or to offer quality products and services. Spring Oaks collects nonpublic personal information about consumers from various sources. These sources and examples of types of information collected include:',
          items: [
            'Name, address, telephone number, social security number or taxpayer ID number, date of birth, employment status, annual income, and net worth;',
            'Information about transactions with us and account custodian(s), such as account balance, types of transactions, parties to the transactions.',
            'Information received from consumer reporting agencies, such as credit reports, prior owners of the account, government agencies and other companies.',
          ],
          table: [
            [
              'Reasons we can share your personal information',
              'Does Spring Oaks share?',
              'Can you limit this sharing?',
            ],
            [
              'For our everyday business purposes – such as to process your transactions, maintains your account(s), respond to court orders and legal investigations or report to credit bureaus',
              'Yes',
              'No',
            ],
            ['For our marketing purposes – to offer our products and services to you', 'No', 'No'],
            ['For joint marketing with other financial companies', 'No', 'We do not share'],
            [
              'For our affiliates’ everyday business purposes – such as information about your transactions and experiences',
              'Yes',
              'No',
            ],
            [
              'For our affiliates’ everyday business purposes – such as information about your creditworthiness',
              'Yes',
              'No',
            ],
            ['For nonaffiliates to market to you', 'No', 'We do not share'],
          ],
          footer: [
            'Definitions: Affiliates are companies related by common ownership or control. They can be financial and non-financial companies.',
            'Nonaffiliates are companies not related by common ownership or control. They can be financial and non-financial companies.',
            'Joint marketing is a formal agreement between nonaffiliated financial companies that together market financial products or services to you. Spring Oaks does not jointly market.',
          ],
        },
        {
          heading: 'Disclosure of Information to Nonaffiliated Third Parties',
          description:
            'Spring Oaks does not disclose nonpublic personal information to nonaffiliated third parties, except under one of the GLBA privacy exceptions, as described below.',
          items: [
            <>
              Types of Permitted Disclosures – The Exceptions
              <ol>
                <li>
                  In certain circumstances, Regulation P permits Spring Oaks to share nonpublic
                  personal information about its consumers with nonaffiliated third parties without
                  providing an opportunity for those individuals to opt out. These circumstances
                  include sharing information with a nonaffiliated entity:
                  <ol>
                    <li>
                      As necessary to effect, administer, or enforce a transaction that a client
                      requests or authorizes;
                    </li>
                    <li>
                      In connection with processing or servicing a collection account or a service a
                      client authorizes; and
                    </li>
                    <li>
                      In connection with maintaining or servicing a collection account with Spring
                      Oaks.
                    </li>
                  </ol>
                </li>
              </ol>
            </>,
          ],
        },
        {
          heading: 'Service Providers',
          items: [
            'From time to time, we may have relationships with nonaffiliated third parties (such as attorneys, auditors, custodians, and other consultants), who, in the ordinary course of providing their services to us, may require access to information containing nonpublic information. These third-party service providers are necessary for us to provide our collection services.',
            'When we are not comfortable that service providers (e.g., attorneys, auditors, and other financial institutions) are already bound by duties of confidentiality, we require assurances from those service providers that they will maintain the confidentiality of nonpublic information they obtain from or through us.',
            'In addition, we select and retain service providers that we believe are capable of maintaining appropriate safeguards for nonpublic information, and we will require contractual agreements from our service providers that they will implement and maintain such safeguards.',
          ],
        },
        {
          heading: 'Processing and Servicing Transactions',
          items: [
            <>
              We may also share information when it is necessary to effect, administer, or enforce a
              transaction requested or authorized by our consumers.
              <ol>
                <li>
                  In this context, "necessary to effect, administer, or enforce a transaction":
                  includes what is required or is a usual, appropriate, or acceptable method to
                  carry out the transaction or service of which the transaction is a part, and
                  record, service, or maintain the consumer’s account in the ordinary course of
                  providing collection services.
                </li>
              </ol>
            </>,
          ],
        },
        {
          heading: 'Sharing as Permitted or Required by Law',
          items: [
            <>
              Spring Oaks may disclose information to nonaffiliated third parties as required or
              allowed by law.
              <ol>
                <li>
                  For example, this may include disclosures in connection with a subpoena or similar
                  legal process, a fraud investigation, an audit or examination.
                </li>
              </ol>
            </>,
            'By understanding how we share data with our clients, their agents, service providers, parties related to transactions in the ordinary course of business, or joint marketers, we endeavor to ensure that client data is shared only within the exceptions noted above.',
          ],
        },
        {
          heading: 'Privacy Notice',
          items: [
            'Spring Oaks will send a Privacy Notice under Regulation P on accounts it owns consistent with this Policy.',
          ],
        },
      ],
    },
    {
      heading: 'Auditing Procedures',
      body: 'Spring Oaks will periodically monitor electronic systems such as e-mail and Internet. Any direct, indirect, or attempted violation of this Policy, by or on behalf of a User, and any actual or attempted violation by a Third Party on behalf of a User, shall be considered a violation of the Policy by the User, and the User shall be held directly accountable. In the event we become aware that any User activity may have violated this Policy and/or exposed us to civil or criminal liability, Spring Oaks reserves the right to investigate such activity; monitor, collect evidence, and block access to such material; and cooperate with legal authorities and Third Parties in investigating any alleged violations of this Policy. We also reserve the right to implement technical mechanisms to prevent policy violations including electronic monitoring of systems such as e-mail and Internet. Users who violate this Policy or any other Spring Oaks-published policies or standards may be subject to disciplinary action by us, up to and including immediate termination from employment. In addition, conduct that is unlawful under applicable laws may subject Users to civil and, in some cases, criminal prosecution.',
    },
    {
      heading: 'Links to Other Websites',
      body: 'Our website may contain links to enable you to visit other websites of interest easily. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide while visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.',
    },
    {
      heading: 'How We Protect Information',
      body: [
        'Spring Oaks has implemented physical, electronic, and procedural security safeguards to protect against the unauthorized release of or access to personal information. We employ internal and external system safeguards designed to protect confidentiality and security of personal information. The confidentiality of any communication or material transmitted to or from Spring Oaks via the website or via e-mail cannot be, and is not, guaranteed. You acknowledge that the technical processing and transmission of the website’s content may be transferred unencrypted and involve: (a) transmissions over various networks; and (b) changes to confirm and adapt to technical requirements of connecting networks or devices.',
        'If any questions arise about security, please contact us using the information provided above.',
      ],
    },
    {
      heading: 'Frequency of Training and Testing',
      body: [
        'All Spring Oaks personnel will receive training on our company policies applicable to their position upon hire or promotion. The same personnel will complete attestations and assessments on those policies upon completion of training and on an annual basis.',
        'Questions or comments about our data practices can be submitted to:',
        'Spring Oaks Capital, LLC\nP.O. Box 1216\nChesapeake, VA 23327-1216',
        'Or by calling us, toll-free, at 866-281-3065.',
      ],
    },
  ],
  'error.title': 'Page not found',
  'error.desc': 'The page you were looking for does not exist',
  'error.page.altimage': 'Woman holding spring oaks capital logo',
  'error.page.button': 'Back to home page',
  'contactUs.title': 'Do you have a question?',
  'contactUs.subtitle': "Fill out the form below and we'll get back to you shortly",
  'contactUs.name': 'Name',
  'contactUs.email': 'Email',
  'contactUs.reference': 'Reference number',
  'contactUs.phone': 'Phone number',
  'contactUs.query': 'Tell us your concern',
  'contactUs.information': 'Information',
  'contactUs.help': 'How can we help?',
  'contactUs.submit': 'Submit',
  'contactUs.disclaimer': 'If you have a concern regarding your account please contact us',
  'contactUs.disclaimerDetails':
    'Spring Oaks Capital, LLC<br />P.O. Box 1216<br />Chesapeake, VA 23327-1216<br />(866) 281-3065<br />Email: <a href="mailto:customerservice@springoakscapital.com">customerservice@springoakscapital.com</a><br /><br />Office Hours:<br />M-Thurs. 8am-11pm EST<br />F 8am-8pm EST',
  'contactUs.disclaimerDetails2':
    'Catherine Calko, Esq.<br />Chief Compliance Officer<br />Company RMAi Certification number: C1911-1132<br />Individual RMAi Certification number: P2112-1448<br />(833) 398-0024<br />Consumer education: <a target="_blank" href="http://rmaintl.org/consumers">rmaintl.org/consumers</a>',
  'contactUs.altimage': 'Two people meeting with a phone',
  'contactUs.success.title': 'Thanks for contacting us',
  'contactUs.success.desc': 'We will respond shortly to your inquiry.',
  'contactUs.success.button': 'Back to home',
  'contactUs.errorMessage':
    'Ooops! There was an error. Make sure all the fields have the corresponding content',
  'contactUs.tooltip.title': 'What is the reference number?',
  'contactUs.tooltip.desc':
    'The Reference Number is the unique number we use to identify your account. You can find your reference number on the letter we mailed you and on each email we send you.',
  'about.header.title': 'Who we are',
  'about.header.desc':
    "We're on a <b>mission to help consumers</b> reduce the burden of their financial obligations",
  'about.card.title':
    'We came together because we believe there is a better way to help consumers deal with their debt',
  'about.card.desc':
    "At Spring Oaks Capital, we believe that <b>debt collection doesn't need to be a stressful</b> or negative experience. We're laser-focused on creating <b>great outcomes</b> for all consumers.",
  'about.card.button': 'Contact us',
  'about.careers.title': 'Career Openings',
  'about.careers': [
    {
      title: 'Collections Associate',
      city: 'Chesapeake, VA',
      url: '/',
    },
  ],
  'about.team.title': 'Our Team',
  'about.partners.title': 'Our Industry Partners and Accreditations',
  'faqSection.title': 'Frequently Asked Questions',
  'faqSection.questions': [
    {
      title: 'How do I access my account details?',
      answer:
        'We offer the ability to access your account through our self-service portal. You can access your account by clicking the View my account button at the top of the page.',
    },
    {
      title: 'Why are you contacting me?',
      answer:
        'Your account was originated by another financial institution, but is now owned by Spring Oaks Capital SPV, LLC. We would like to help find a resolution that works for you.',
    },
    {
      title: 'How can I request my original loan documents?',
      answer:
        'You can request original loan documents by giving us a call at (866)-281-3065 or through the Contact Us page.',
    },
  ],
  'team.quotes': [
    {
      quote:
        'I enjoy being able to help people out that have simply fallen on hard times. I have been there myself, so it is very satisfying when I am able to help them in their time of need.',
      name: 'Melissa',
      src: 'team-members/avatar-melissa.jpeg',
    },
    {
      quote:
        'I enjoy supporting the mission of helping each consumer get closer to financial freedom. I accomplish this by providing great customer service on every call and demonstrating a willingness to work with them no matter their situation.',
      name: 'Arshanika',
      src: 'team-members/avatar-arshanika.jpeg',
    },
    {
      quote:
        "Working to help our customers become debt free has been nothing short of amazing. I enjoy being able to help our customers relieve financial stress by providing the best customer support and modeling our company's core values.",
      name: 'Alaysia',
      src: 'team-members/avatar-alaysia.jpeg',
    },
    {
      quote:
        "Every consumer's individual situation is different and I always remember that I am a consumer first. This is a total team effort between you and I.  Let me help you find the best plan of action that fits your individual needs.",
      name: 'Will',
      src: 'team-members/avatar-will.jpeg',
    },
    {
      quote:
        'I really enjoy the environment Spring Oaks Capital provides. What makes this company stand above the rest is the demand to treat everyone the way anyone would like to be treated, and that applies to employees as well as our customers. We are humans servicing other humans who have fallen on hard times. With great pleasure I treat all my customers as I would have wanted to be treated when I was in the same situation.',
      name: 'Shameeka',
      src: 'team-members/avatar-shameeka.jpeg',
    },
  ],
};
